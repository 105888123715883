<template>
	<div class="page">
		<img src="../assets/404.png" />
		<p>{{lang==6?'找不到页面':'Page not found'}}</p>
		<p v-if="lang==6">可能是因为您访问了错误或过时的网址，或者我们已移动或重命名了相关网页<br>
			请再次价差您输入的网址</p>
		<p v-else>It may be that you have accessed the wrong or outdated URL, or that we have moved or renamed the
			relevant page<br>
			Please reprice the URL you entered</p>
	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss">
	.page {
		color: #FFF;
		text-align: center;
		padding-bottom: 80px;
		padding-top: 200px;

		:nth-child(2) {
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 40px;
			font-weight: bold;
			margin: 20px 0;
			text-transform: uppercase;
		}
	}

	img {
		width: 811px;
		// margin-top: 100px;
	}
</style>